import { Role } from './role';
import { Timestamp } from 'rxjs/internal/operators/timestamp';

export class Campaigns {
  id: number;
  name: string;
  createdBy: string;
  lastUpdatedBy: string;
  cupids: [];
  status:string;
}

