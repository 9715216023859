import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';
import { DceHeaderModule } from './dce-header/dce-header.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { DataTableModule } from 'angular5-data-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angular2CsvModule } from 'angular2-csv';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from '@cs/material';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AdsHeaderModule } from './ads-header/ads-header.module';
import { ModalService } from '@cs/core-data';
import { create } from 'domain';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';


@NgModule({
  imports: [
    MatDatepickerModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    Angular2CsvModule,
    AdsHeaderModule,
    DceHeaderModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    DataTablesModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      newestOnTop: true
    }),
    DataTableModule.forRoot(),
    LoadingBarHttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    AppRoutingModule,
    NgxSpinnerModule,
  ],
  schemas:[NO_ERRORS_SCHEMA], 
  declarations: [AppComponent],
  providers: [ModalService],
  exports: [RouterModule, MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
