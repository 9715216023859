import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignService } from './approval/campaign';
import { AuthGuard } from '@cs/core-data';
import { PermissionGuard } from './../../../../libs/core-data/src/lib/session/permission.guard';
import { RequestResetComponent } from './request-reset/request-reset.component';
import { ResponseResetComponent } from './response-reset/response-reset.component';


const menuApps = [
  {
    icon: 'fas fa-plus-square',
    name: 'Add Offer'
  },
  {
    icon: 'fas fa-clone',
    name: 'Clone'
  },
  {
    icon: 'fas fa-edit',
    name: 'Edit'
  }
  // {
  //   icon: 'fas fa-download',
  //   name: 'Download'
  // },
  // {
  //   icon: 'fas fa-file-export',
  //   name: 'Export'
  // }
];

/**
 * Fix for ST-1823: 'canActivate' (defined in auth.guard.ts) restricts the unauthorized view of the protected webpages when the user is not logged in
 * */
const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'campaign_approval',
    loadChildren: './approval/campaign/campaign.module#CampaignModule',
    data: {
      appTitle: 'Campaign Approval'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'create',
    loadChildren: './ads-create/ads-create.module#AdsCreateModule',
    data: {
      appTitle: 'Create'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'dealerOnBoarding',
    loadChildren:
      './dashboard/dealer-on-boarding/dealer-on-boarding.module#DealerOnBoardingModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'dealers',
    loadChildren: './dashboard/admin/dealers/dealers.module#DealersModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'dealers/boarding',
    loadChildren: './dashboard/admin/dealers/dealers.module#DealersModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'floodlights',
    loadChildren: './dashboard/admin/floodlights/floodlights.module#FloodlightsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'cost',
    loadChildren: './dashboard/admin/cost/cost.module#CostModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: './dashboard/admin/users/users.module#UsersModule',
    canActivate: [AuthGuard],
    data: { roles: [] }
  },
  
  {
    path: 'inventorytools',
    loadChildren: './dashboard/admin/inventorytools/inventorytools.module#InventorytoolsModule',
    canActivate: [AuthGuard],
    data: { roles: [] }
  },

  {
    path: 'ads',
    loadChildren: './main/main.module#MainModule',
    data: {
      appTitle: 'ADS',
      apps: menuApps,
      isAds: true
    },
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: 'exportcsv/:token',
    loadChildren: './main/main.module#MainModule',
    data: {
      appTitle: 'ADSCSV',
      apps: menuApps,
      isAds: false
    }
  },
  // {
  //   path: 'export',
  //   loadChildren: './ads-export/ads-export.module#AdsExportModule',
  //   data: {
  //     appTitle: 'Export'
  //   }
  // },
  {
    path: 'forgotpwd',
    loadChildren: './forgot-password/forgotpwd.module#ForgotPwdModule'
  },
  {
    path: 'request-reset-password',
    loadChildren: './request-reset/request-reset.module#RequestResetModule'
  },
  {
      path: 'response-reset-password/:token',
      loadChildren: './response-reset/response-reset.module#ResponseResetModule'
    },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: 'api-status',
    loadChildren: './api-status/api-status.module#ApiStatusModule'
  },
  {
    path: 'main',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'dcedashboard',
    loadChildren: './dcedashboard/dcedashboard.module#DcedashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'success/:id',
    loadChildren: './ads-success/ads-success.module#AdsSuccessModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'signup',
    loadChildren: './signup/signup.module#SignupModule'
  },
  {
    path: 'search-ads',
    loadChildren: './ads-search/ads-search.module#AdsSearchModule',
    data: {
      appTitle: 'Search Ads',
      apps: [
        {
          icon: 'fas fa-list-alt',
          name: 'View All'
        }
      ]
    },
    canActivate: [AuthGuard, PermissionGuard]
  },
  {
    path: 'search-ads/:id/:idCard',
    loadChildren: './ads-search/ads-search.module#AdsSearchModule',
    data: {
      appTitle: 'Search Ads',
      apps: [
        {
          icon: 'fas fa-list-alt',
          name: 'View All'
        }
      ]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'search-ads/copy/:id/:idCard',
    loadChildren: './ads-search/ads-search.module#AdsSearchModule',
    data: {
      appTitle: 'Search Ads',
      apps: [
        {
          icon: 'fas fa-list-alt',
          name: 'View All'
        }
      ]
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'search-ads-view',
    loadChildren:
      './ads-search/view-all-ads/view-all-ads.module#ViewAllAdsModule',
    data: {
      appTitle: 'Search Ads View',
      apps: [
        {
          icon: 'fas fa-arrow-left',
          name: 'Create Search Ad'
        },
        {
          icon: 'fas fa-download',
          name: 'Download'
        },
        {
          icon: 'fas fa-upload',
          name: 'Submit to Google Ads'
        }
      ]
    },
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'upload-images',
  //   loadChildren:
  //     './ads-upload-images/ads-upload-images.module#AdsUploadImagesModule',
  //   data: {
  //     appTitle: 'Upload Images'
  //   },
  //   canActivate: [AuthGuard]
  // },

  // {
  //   path: 'offer-approval',
  //   loadChildren: './approval/offer/offer.module#OfferModule',
  //   canActivate: [AuthGuard]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [CampaignService]
})
export class AppRoutingModule {}
